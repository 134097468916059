import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize () {
    // Notes on config options:
    // pagebreak_separator:       custom html code for pagebreaks
    // paste_word_valid_elements: Auto clean content pasted from MS Word.
    // paste_data_images:         allow data URI images
    this.tinyMceConfig = {
      branding: false,
      extended_valid_elements: "-ol[start|type|compact|style]",
      menubar: 'edit insert view format table tools',
      plugins: 'advlist autolink charmap code colorpicker contextmenu fullscreen hr link lists nonbreaking pagebreak paste searchreplace table textcolor visualblocks visualchars wordcount uploadimage tiny_mce_wiris',
      pagebreak_separator: "<div class='page_break'/>",
      paste_word_valid_elements: "b,strong,i,em,h1,h2",
      width: '850px',
      height: '350px',
      browser_spellcheck: true,
      paste_data_images: true,
      toolbar: [
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry"
      ]
    }
  }

  connect () {
    let config = Object.assign({ selector: 'textarea.tinymce' }, this.tinyMceConfig)
    tinyMCE.init(config);
  }

  disconnect () {
    tinymce.remove('textarea.tinymce');
  }
}
